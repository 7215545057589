import dompurify from "dompurify";
import less from "less";

/**
 * @description secure html, wrapp css into a container css -> less -> css
 */
export default function useSecureHtml() {
  const sanitizer = dompurify.sanitize;

  const getSecureHtml = (html) => {
    return sanitizer(html);
  };

  const getSecureCss = async (css, wrapperSelector) => {
    let result = await less.render(wrapperSelector + "{" + css + "}");
    return result.css;
  };

  return {
    getSecureHtml,
    getSecureCss,
  };
}
