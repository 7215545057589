import FormView from "models/FormView";
import Service from "./Service";

export default class PaperService extends Service {
  constructor() {
    super("/portal");
  }

  async view(token) {
    let response = await this.makeRequest(
      this.basePath + "/form?token=" + token,
      "GET"
    );
    if (response.isSuccess()) {
      response.setData(Object.assign(new FormView(), response.getData()));
    }
    return response;
  }

  async saveInput(token, draft, userInputs, signature, embedContext) {
    const body = {
      token: token,
      draft: draft,
      userInputs: userInputs,
      signature: signature,
      embedContext: embedContext,
    };
    let response = await this.makeRequest(
      this.basePath + "/form",
      "POST",
      body
    );
    return response;
  }

  /**
   * Form to PDF
   * @returns {Template} paper
   */
  async formPdf(formToken, download = false, filename = "Formular") {
    let response = await this.makeBlobRequest(
      this.basePath + "/form/pdf?token=" + formToken,
      "GET",
      "pdf"
    );
    if (response.isSuccess() && download) {
      this.download(response.getData(), filename + ".pdf");
    }
    return response;
  }

  /**
   * Form to PDF Preview
   * @returns {Template} paper
   */
  async formPdfPreview(formToken, userInputs) {
    let response = await this.makeBlobRequest(
      this.basePath + "/form/pdf-preview?token=" + formToken,
      "POST",
      "pdf",
      { userInputs: userInputs }
    );
    if (response.isSuccess()) {
      this.preview(response.getData());
    }
    return response;
  }
}
