/* eslint-disable no-unused-vars */
import {
  CatchingPokemon,
  Clear,
  Crop,
  Delete,
  FileUpload,
  Save,
} from "@mui/icons-material";
import { Box, Button, Paper, Typography } from "@mui/material";
import Cropper from "cropperjs";
import { useEffect, useState } from "react";
import SignaturePad from "signature_pad";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./Signature.css";
import "cropperjs/dist/cropper.css";
import DialogContainer from "components/DialogContainer/DialogContainer";
import PropTypes from "prop-types";
import { MainContent } from "components";

export default function Signature(props) {
  const VIEWS = {
    START: "start",
    MANUAL: "manual",
    CROPPER: "cropper",
  };
  const [view, setView] = useState(VIEWS.START);
  const [signaturePad, setSignaturePad] = useState();
  const [image, setImage] = useState(props.signature ?? null);
  const [imageCropper, setImageCropper] = useState();

  // Adjust canvas coordinate space taking into account pixel ratio,
  // to make it look crisp on mobile devices.
  // This also causes canvas to be cleared.
  const resizeCanvas = async () => {
    let myCanvas = document.querySelector("#signatureCanvas");
    if (!signaturePad || !signaturePad.canvas || !myCanvas) return;
    let signatureCanvas = signaturePad.canvas;

    // When zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1
    // and only part of the canvas is cleared then.
    var ratio = Math.max(window.devicePixelRatio || 1, 1);

    // This part causes the canvas to be cleared
    signatureCanvas.width = myCanvas.offsetWidth * ratio;
    signatureCanvas.height = myCanvas.offsetHeight * ratio;
    signatureCanvas.getContext("2d").scale(ratio, ratio);

    // This library does not listen for canvas changes, so after the canvas is automatically
    // cleared by the browser, SignaturePad#isEmpty might still return false, even though the
    // canvas looks empty, because the internal data of this library wasn't cleared. To make sure
    // that the state of this library is consistent with visual state of the canvas, you
    // have to clear it manually.
    signaturePad.clear();
  };

  const clearCanvas = () => {
    if (!signaturePad) return;
    signaturePad.clear();
    setImage(null);
  };

  const getCanvasData = () => {
    if (signaturePad) setImage(signaturePad.toDataURL("image/jpeg"));
  };

  const handleUploadImg = () => {
    let file = document.getElementById("file-select").files;
    if (file && file[0]) {
      let fileSize = file[0].size / 1000 / 1000; // convert to MB
      if (fileSize > 0 && fileSize <= 0.5) {
        let reader = new FileReader();
        reader.readAsDataURL(file[0]);
        reader.onload = () => {
          setImage(reader.result);
        };
      }
    }
  };

  const initSignaturePad = async () => {
    const canvas = document.querySelector("#signatureCanvas"),
      localSignaturePad = new SignaturePad(canvas, {
        penColor: "rgb(66, 133, 244)",
        backgroundColor: "rgb(255,255,255)",
      });
    setSignaturePad(localSignaturePad);
  };

  const initCropper = () => {
    const cropperImage = document.getElementById("Signatur-cropper-image");
    if (!cropperImage?.src) {
      return;
    }
    const cropper = new Cropper(cropperImage, {
      autoCropArea: 1,
      zoomable: false,
    });
    setImageCropper(cropper);
  };

  const handleCropper = () => {
    if (imageCropper) {
      let newImage = imageCropper.getCroppedCanvas({
        maxWidth: 1000,
        fillColor: "rgb(255,255,255)",
      });
      if (newImage) {
        newImage = newImage.toDataURL("image/jpeg");
        if (newImage) {
          setImage(newImage);
        }
      }
    }
    setView(VIEWS.START);
  };

  useEffect(() => {
    if (view === VIEWS.MANUAL) {
      initSignaturePad();
    } else if (view === VIEWS.CROPPER) {
      if (imageCropper) {
        imageCropper.destroy();
      }
      setTimeout(initCropper, 100);
    }
  }, [view]);

  useEffect(() => {
    if (props.onChange) props.onChange(image);
  }, [image]);

  useEffect(() => {
    if (!signaturePad) return;
    // On mobile devices it might make more sense to listen to orientation change,
    // rather than window resize events.
    resizeCanvas(signaturePad);
    signaturePad.addEventListener("endStroke", () => {
      getCanvasData();
    });
    window.onresize = resizeCanvas;
  }, [signaturePad]);

  const goToStart = () => {
    setView(VIEWS.START);
  };

  /*
 
  */
  return (
    <>
      <div className="Signature-root">
        {view === VIEWS.START && (
          <div className="Signature-view-start">
            <Paper
              variant="outlined"
              className="Signature-type-select"
              onClick={() => setView(VIEWS.MANUAL)}
            >
              <div className="Signature-type-icon">
                <i className="fas fa-signature"></i>
              </div>
              <Typography variant="button" gutterBottom>
                Handschriftlich
              </Typography>
              <Typography variant="body2">
                Unterschreiben Sie direkt auf Ihrem Endgerät.
              </Typography>
            </Paper>

            <Box component="label">
              <Paper variant="outlined" className="Signature-type-select">
                <div className="Signature-type-icon">
                  <i className="fas fa-upload"></i>
                </div>
                <Typography variant="button" gutterBottom>
                  Bild hochladen
                </Typography>
                <Typography variant="body2">
                  Laden Sie ein Bild Ihrer Unterschrift hoch.
                </Typography>

                <input
                  type="file"
                  hidden
                  accept=".png,.jpg,.img,.jpeg"
                  id="file-select"
                  onChange={handleUploadImg}
                />
              </Paper>
            </Box>
          </div>
        )}
        {view === VIEWS.MANUAL && (
          <div className="Signature-manual">
            <MainContent
              pageTitle="Hier unterzeichnen:"
              pageTitleVariant="body"
              goBack={goToStart}
              afterTitle={
                <>
                  <Button
                    startIcon={<Clear />}
                    className="Signature-manual-clear"
                    onClick={clearCanvas}
                    variant="contained"
                    color="error"
                    size="small"
                  >
                    Clear
                  </Button>
                </>
              }
            >
              <canvas id="signatureCanvas"></canvas>
            </MainContent>
          </div>
        )}
        {(view === VIEWS.START || view === VIEWS.MANUAL) && image && (
          <div className="Signature-result">
            <MainContent
              pageTitle="Ergebnis:"
              pageTitleVariant="body"
              afterTitle={
                <Button
                  size="small"
                  startIcon={<Crop />}
                  variant="contained"
                  onClick={() => setView(VIEWS.CROPPER)}
                >
                  Zuschneiden
                </Button>
              }
            >
              <div>
                <div className="Signatur-image-wrapper">
                  <img src={image} />
                </div>
              </div>
            </MainContent>
          </div>
        )}
      </div>
      <DialogContainer
        title="Zuschneiden"
        open={view === VIEWS.CROPPER}
        handleCancel={() => setView(VIEWS.START)}
        okBtnLabel="Zuschneiden"
        handleOk={handleCropper}
      >
        <div className="Signatur-cropper-wrapper">
          <img id="Signatur-cropper-image" src={image} />
        </div>
      </DialogContainer>
    </>
  );
}

Signature.propTypes = {
  onChange: PropTypes.func,
  signature: PropTypes.string,
};
