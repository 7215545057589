import PropTypes from "prop-types";
import { Snackbar, Alert } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
/**
 * @param {bool} open
 * @param {function} onClose
 * @param {string} severity
 * @param {string} message
 */
export default function Notify(props) {
  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={props.onClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <>
      <Snackbar
        open={props.open}
        autoHideDuration={6000}
        action={action}
        onClose={props.onClose}
        message={props.message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={props.onClose} severity={props.severity}>
          {props.message}
        </Alert>
      </Snackbar>
    </>
  );
}

Notify.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  severity: PropTypes.string,
  message: PropTypes.string,
};
