import Service from "./Service";
import RequestResponse from "./RequestResponse";
export default class AssetsService extends Service {
  constructor() {
    super("/assets", null);
  }
  /**
   * @param {string} endpoint
   */
  async makeCssRequest(endpoint, method) {
    let myResponse = new RequestResponse();
    return fetch(process.env.REACT_APP_API_URL + endpoint, {
      method: method,
      headers: {
        "Content-Type": "text",
      },
    })
      .then((response) => {
        const contentType = response.headers.get("content-type");
        const isText = contentType && contentType.includes("text/css");
        myResponse.setSuccess(String(response.status).charAt(0) == 2);
        myResponse.setMessage(response.statusText);
        if (!myResponse.isSuccess() && !isText) {
          throw new Error("Oops, we haven't a CSS! (" + response.status + ")");
        }
        return response.text();
      })
      .then((data) => {
        if (data) {
          if (data.error && data.error.message) {
            throw new Error(data.error.message);
          }
          myResponse.setData(data);
        }
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
        myResponse.setMessage(error.message);
        myResponse.setSuccess(false);
      })
      .then(() => {
        return myResponse;
      });
  }
  /**
   * Get Default Css
   * @returns {string} css
   */
  async getDefaultCss() {
    let response = await this.makeCssRequest(
      "/assets/css/default.min.css",
      "GET"
    );
    return response;
  }

  /**
   * Get Fonts Css
   * @returns {string} css
   */
  getFontsUrl() {
    return this.apiUrl + "/assets/fonts/all.css";
  }
}
