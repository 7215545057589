import { Typography } from "@mui/material";
import { MainContent } from "components";

export default function NotFoundPage() {
  return (
    <MainContent pageTitle="Seite nicht gefunden">
      <Typography variant="h4" component="h4">
        Die angeforderte Seite existiert nicht oder wurde verschoben.
      </Typography>
    </MainContent>
  );
}
