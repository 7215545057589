/* eslint-disable no-unused-vars */
import { Button, CircularProgress, Typography } from "@mui/material";
import DialogContainer from "components/DialogContainer/DialogContainer";
import FormContainer from "components/FormContainer/FormContainer";
import { useEffect, useState } from "react";
import "./EmbeddedFormPage.css";
import { useSearchParams } from "react-router-dom";
import PortalService from "services/PortalService";
import Notify from "components/Notify/Notify";
import Signature from "components/Signature/Signature";
import { PictureAsPdf } from "@mui/icons-material";
import WelcomeDialog from "components/WelcomeDialog/WelcomeDialog";

export default function FormPage() {
  const [searchParams] = useSearchParams();
  const [validation, setValidation] = useState({
    fields: [],
    openModal: false,
  });
  const [displayMessage, setDisplayMessage] = useState();
  const service = new PortalService();
  const [formData, setFormData] = useState();
  const [notify, setNotify] = useState({
    open: false,
    msg: null,
    severity: "info",
  });
  const [signature, setSignature] = useState({ open: false, image: null });
  const [currentUserInput, setCurrentUserInput] = useState([]);
  const [showSuccessPage, setShowSuccessPage] = useState(false);
  const [welcomeDialogOpen, setWelcomeDialogOpen] = useState(true);
  const [formPdf, setFormPdf] = useState(null);

  const [contentHeight, setContentHeight] = useState(null);

  useEffect(() => {
    if (searchParams.get("token")) fetchForm();
  }, []);

  useEffect(() => {
    console.log("content height changed:", contentHeight);
    window.top.postMessage({ height: getDocumentHeight() }, "*");
  }, [contentHeight]);

  const reset = () => {
    setDisplayMessage(null);
    setValidation({
      fields: [],
      openModal: false,
    });
    setSignature({ open: false, image: null });
    setNotify({
      open: false,
      msg: null,
      severity: "info",
    });
    setFormData(null);
  };

  async function fetchForm() {
    let response = await service.view(searchParams.get("token"));
    if (response.isSuccess()) {
      document.title = "mb Forms - " + response.getData().name;
      setFormData(response.getData());
    } else {
      openNotify("Fehler beim Abholen: " + response.getMessage(), "error");
      setDisplayMessage("Fehler:  " + response.getMessage());
    }
  }

  const saveFormInput = async (userInputs, isDraft) => {
    reset();
    if (!isDraft && !signature.image) {
      openNotify("Eine Unterschrift ist erforderlich!", "error");
      return;
    }
    let response = await service.saveInput(
      searchParams.get("token"),
      isDraft,
      userInputs,
      signature?.image
    );
    if (response.isSuccess()) {
      if (isDraft) {
        openNotify("Eingaben als Entwurf gespeichert", "success");
        fetchForm();
      } else {
        openNotify("Eingaben abgesendet!", "success");
        setShowSuccessPage(true);
        // download form
        let responsePdf = await service.formPdf(
          searchParams.get("token"),
          false
        );
        if (responsePdf.isSuccess()) setFormPdf(responsePdf.getData());
        else openNotify("PDF konnte nicht generiert werden", "error");
      }
    } else {
      openNotify("Fehler beim Speichern!", "error");
      setDisplayMessage("Fehler beim Speichern. Laden Sie die Seite neu.");
    }
  };

  const handleSave = async (userInputs, isDraft) => {
    await setCurrentUserInput(userInputs);
    if (!isDraft) {
      openSignature();
    } else {
      saveFormInput(userInputs, isDraft);
    }
  };

  const handleSignatureSave = (signatureImage) => {
    let newSignature = { ...signature, ...{ image: signatureImage } };
    setSignature(newSignature);
  };

  const closeNotify = () => {
    setNotify({ ...notify, ...{ open: false } });
  };

  const closeValidationModal = () => {
    setValidation(Object.assign({ ...validation }, { openModal: false }));
  };

  const openSignature = () => {
    let newSignature = { ...signature, ...{ open: true } };
    setSignature(newSignature);
  };

  const saveSignature = () => {
    let newSignature = { ...signature, ...{ open: false } };
    setSignature(newSignature);
  };

  const openNotify = (msg, severity) => {
    setNotify({
      open: true,
      msg: msg,
      severity: severity,
    });
  };

  const downloadForm = async () => {
    if (!formPdf) {
      openNotify("Fehler beim Herunterladen des Formulars!", "error");
      return;
    }
    service.download(formPdf, "Formular.pdf");
  };

  const getDocumentHeight = () => {
    let height = window.document.documentElement.scrollHeight;
    return height;
  };

  const resizeObserver = new ResizeObserver(() => {
    setContentHeight(getDocumentHeight());
  });

  let container = document.querySelector("#root");
  for (let child of container.children) {
    resizeObserver.observe(child);
  }

  window.addEventListener(
    "message",
    (e) => {
      let message = e.data;
      if (message.action === "getHeight") {
        window.top.postMessage({ height: getDocumentHeight() }, "*");
      }
    },
    false
  );

  return (
    <div className="FormPage-root">
      {displayMessage && <Typography>{displayMessage}</Typography>}
      {!searchParams.get("token") ? (
        <Typography>Kein Token gefunden.</Typography>
      ) : (
        <>
          {!showSuccessPage && (
            <>
              {true && (
                <WelcomeDialog
                  open={welcomeDialogOpen && Boolean(formData?.newRecipient)}
                  handleOk={() => setWelcomeDialogOpen(false)}
                />
              )}
              <FormContainer
                onSave={handleSave}
                onValidationError={(msgArray) =>
                  setValidation({ openModal: true, fields: msgArray })
                }
                setNotify={setNotify}
                portalForm={formData}
                needsLegalAgreement={false}
              />
              <DialogContainer
                open={validation.openModal}
                handleOk={closeValidationModal}
                title="Fehlerhafte Eingabe"
              >
                {validation.fields.length > 0 && (
                  <>
                    {validation.fields.map((el, index) => (
                      <div key={index}>
                        <Typography component="span">
                          <b>{el.label}: </b>
                        </Typography>
                        <Typography component="span">{el.msg}</Typography>
                      </div>
                    ))}
                  </>
                )}
              </DialogContainer>
              <DialogContainer
                open={signature.open}
                title="Unterschreiben"
                handleCancel={saveSignature}
                handleOk={
                  signature.image
                    ? () => saveFormInput(currentUserInput, false)
                    : undefined
                }
                okBtnLabel="Absenden"
              >
                <Signature
                  signature={signature.image}
                  onChange={handleSignatureSave}
                />
              </DialogContainer>
            </>
          )}
          {showSuccessPage && (
            <>
              <Typography gutterBottom>
                Vielen Dank, dass Sie Ihr Formular ausgefüllt haben. Ihr
                Formular wird nun automatisch an Ihren Ansprechpartner
                übermittelt.
              </Typography>
              <Button
                disabled={!formPdf}
                variant="contained"
                component="label"
                startIcon={
                  formPdf ? (
                    <PictureAsPdf />
                  ) : (
                    <CircularProgress size="1rem" color="inherit" />
                  )
                }
                onClick={downloadForm}
              >
                Formular herunterladen
              </Button>
            </>
          )}
        </>
      )}

      <Notify
        open={notify.open}
        severity={notify.severity}
        message={notify.msg}
        onClose={closeNotify}
      />
    </div>
  );
}
