import { MainContent } from "components";
import { Typography } from "@mui/material";

const PRIVACY_CONTENT_1 = [
  {
    label: "Wir nehmen Datenschutz ernst",
    subtitle: false,
    content: `Der Schutz Ihrer Privatsphäre bei der Verarbeitung persönlicher Daten ist für uns ein wichtiges Anliegen. Wenn Sie unsere Webseite besuchen, speichern unsere Webserver standardmäßig die IP Ihres Internet Service Provider, die Webseite, von der aus Sie uns besuchen, die Webseiten, die Sie bei uns besuchen sowie das Datum und die Dauer des Besuches. Diese Informationen sind für die technische Übertragung der Webseiten und den sicheren Serverbetrieb zwingend erforderlich. Eine personalisierte Auswertung dieser Daten erfolgt nicht.<br /><br /> Verantwortliche Stelle:<br /> mb Support GmbH<br /> Friedenstraße 18<br /> D-93053 Regensburg<br /> Geschäftsführer Martin Böddecker<br /> Tel +49 941 94 260-0<br /> E-Mail: info@mbsupport.de<br /> <br /> Sofern Sie uns Daten per Kontakt-Formular senden, werden diese Daten im Zuge der Datensicherung auf unseren Servern gespeichert. Ihre Daten werden von uns ausschließlich zur Bearbeitung Ihres Anliegens verwendet. Ihre Daten werden streng vertraulich behandelt. Eine Weitergabe an Dritte erfolgt nicht.`,
  },
  {
    label: "Personenbezogene Daten",
    subtitle: false,
    content: `Personenbezogene Daten sind Daten über Ihre Person. Diese beinhalten Ihren Namen, Ihre Adresse und Ihre Email Adresse. Sie müssen auch keine personenbezogenen Daten preisgeben um unsere Internetseite besuchen zu können. In einigen Fällen benötigen wir Ihren Namen und Adresse sowie weitere Informationen um Ihnen die gewünschte Dienstleistung anbieten zu können.<br /> <br /> Das Gleiche gilt für den Fall, dass wir Sie auf Wunsch mit Informationsmaterial beliefern bzw. wenn wir Ihre Anfragen beantworten. In diesen Fällen werden wir Sie immer darauf hinweisen. Außerdem speichern wir nur die Daten, die Sie uns automatisch oder freiwillig übermittelt haben.<br /> <br /> Wenn Sie einen unserer Services nutzen, sammeln wir in der Regel nur die Daten die notwendig sind um Ihnen unseren Service bieten zu können. Möglicherweise fragen wir Sie nach weiteren Informationen, die aber freiwilliger Natur sind. Wann immer wir personenbezogene Daten verarbeiten, tun wir dies um Ihnen unseren Service anbieten zu können oder um unsere kommerziellen Ziele zu verfolgen.`,
  },
  { label: "Automatisch gespeicherte Daten", subtitle: false },
  {
    label: "Server-Log-Dateien",
    subtitle: true,
    content: `Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:<br/><ul><li>Datum und Uhrzeit der Anforderung</li><li>Name der angeforderten Datei</li><li>Seite, von der aus die Datei angefordert wurde</li><li>Zugriffsstatus (Datei übertragen, Datei nicht gefunden, etc.)</li><li>verwendeter Webbrowser und verwendetes Betriebssystem</li><li>vollständige IP-Adresse des anfordernden Rechners</li><li>übertragene Datenmenge</li></ul>Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis unseres berechtigten Interesses an der Verbesserung der Stabilität und Funktionalität unserer Website.<br/><br/>Aus Gründen der technischen Sicherheit, insbesondere zur Abwehr von Angriffsversuchen auf unseren Webserver, werden diese Daten von uns kurzzeitig gespeichert. Ein Rückschluss auf einzelne Personen ist uns anhand dieser Daten nicht möglich. Nach spätestens sieben Tagen werden die Daten durch Verkürzung der IP-Adresse auf Domain-Ebene anonymisiert, so dass es nicht mehr möglich ist, einen Bezug zum einzelnen Nutzer herzustellen. In anonymisierter Form werden die Daten daneben zu statistischen Zwecken verarbeitet; ein Abgleich mit anderen Datenbeständen oder eine Weitergabe an Dritte, auch in Auszügen, findet nicht statt. Lediglich im Rahmen unserer Serverstatistik, die wir alle zwei Jahre in unserem Tätigkeitsbericht veröffentlichen, findet eine Darstellung der Anzahl der Seitenaufrufe statt.`,
  },
  {
    label: "Cookies",
    subtitle: true,
    content: `Wenn Sie unsere Internetseiten besuchen speichern wir möglicherweise Informationen auf Ihrem Computer in Form von Cookies. Cookies sind kleine Dateien die von einem Internetserver an Ihren Browser übertragen und auf dessen Festplatte gespeichert werden. Lediglich die Internet Protokoll Adresse wird hierbei gespeichert – keine personenbezogenen Daten. Diese Information die in den Cookies gespeichert wird erlaubt es, Sie bei dem nächsten Besuch auf unserer Internetseite automatisch wiederzuerkennen, wodurch Ihnen die Nutzung erleichtert wird<br/><br>Natürlich können Sie unsere Internetseiten auch besuchen ohne Cookies zu akzeptieren. Wenn Sie nicht möchten, dass Ihr Computer beim nächsten Besuch wiedererkannt wird können Sie die Verwendung von Cookies auch ablehnen indem Sie die Einstellungen in Ihrem Browser auf „Cookies ablehnen“ ändern. Die jeweilige Vorgehensweise finden Sie in der Bedienungsanleitung Ihres jeweiligen Browsers. Wenn Sie die Verwendung von Cookies ablehnen, kann es jedoch zu Einschränkungen in der Nutzung mancher Bereiche unserer Internetseiten kommen.</br>`,
  },
  {
    label: "Automatisch gespeicherte nicht personenbezogene Daten",
    subtitle: false,
    content: `Wenn Sie unsere Internetseiten besuchen, speichern wir aus administrativen und technischen Gründen bestimmte Informationen. Diese sind: Typ und Version des verwendeten Browsers, Datum und Uhrzeit des Zugriffs, sowie die IP Adresse.<br/><br/>Diese Daten werden anonymisiert und lediglich für statistische Zwecke bzw. dafür verwendet, unsere Internet- und Onlinedienste zu verbessern.<br/><br/>Diese anonymisierten Daten werden – getrennt von personenbezogenen Daten – auf sicheren Systemen gespeichert und können keinen individuellen Personen zugeordnet werden. Das bedeutet, dass Ihre personenbezogenen Daten jederzeit geschützt bleiben.`,
  },
  {
    label: "Sicherheit",
    subtitle: false,
    content: `Wir haben technische und administrative Sicherheitsvorkehrungen getroffen um Ihre personenbezogenen Daten gegen Verlust, Zerstörung, Manipulation und unautorisierten Zugriff zu schützen. All unsere Mitarbeiter sowie für uns tätige Dienstleister sind auf die gültigen Datenschutzgesetze verpflichtet.<br/><br/>Wann immer wir personenbezogene Daten sammeln und verarbeiten werden diese verschlüsselt bevor sie übertragen werden. Das heißt, dass Ihre Daten nicht von Dritten missbraucht werden können. Unsere Sicherheitsvorkehrungen unterliegen dabei einem ständigen Verbesserungsprozess und unsere Datenschutzerklärungen werden ständig überarbeitet. Bitte stellen Sie sicher, dass Ihnen die aktuellste Version vorliegt.`,
  },
  {
    label: "Betroffenenrechte",
    subtitle: false,
    content: `Sie haben jederzeit ein Recht auf Auskunft, Berichtigung, Löschung oder Einschränkung der Verarbeitung Ihrer gespeicherten Daten, ein Widerspruchsrecht gegen die Verarbeitung sowie ein Recht auf Datenübertragbarkeit und auf Beschwerde gemäß den Voraussetzungen des Datenschutzrechts.`,
  },
  {
    label: "Recht auf Auskunft:",
    subtitle: true,
    content: `Sie können von uns eine Auskunft verlangen, ob und in welchem Ausmaß wir Ihre Daten verarbeiten.`,
  },
  {
    label: "Recht auf Berichtigung:",
    subtitle: true,
    content: `Verarbeiten wir Ihre Daten, die unvollständig oder unrichtig sind, so können Sie jederzeit deren Berichtigung bzw. deren Vervollständigung von uns verlangen.`,
  },
  {
    label: "Recht auf Löschung:",
    subtitle: true,
    content: `Sie können von uns die Löschung Ihrer Daten verlangen, sofern wir diese unrechtmäßig verarbeiten oder die Verarbeitung unverhältnismäßig in Ihre berechtigten Schutzinteressen eingreift. Bitte beachten Sie, dass es Gründe geben kann, die einer sofortigen Löschung entgegenstehen, z.B. im Fall von gesetzlich geregelten Aufbewahrungspflichten.<br/><br/>Unabhängig von der Wahrnehmung Ihres Rechts auf Löschung, werden wir Ihre Daten umgehend und vollständig löschen, soweit keine diesbezügliche rechtsgeschäftliche oder gesetzliche Aufbewahrungspflicht entgegensteht.`,
  },
  {
    label: "Recht auf Einschränkung der Verarbeitung",
    subtitle: true,
    content: `Sie können von uns die Einschränkung der Verarbeitung Ihrer Daten verlangen, wenn<ul><li>Sie die Richtigkeit der Daten bestreiten, und zwar für eine Dauer, die es uns ermöglicht, die Richtigkeit der Daten zu überprüfen.</li><li>die Verarbeitung der Daten unrechtmäßig ist, Sie aber eine Löschung ablehnen und stattdessen eine Einschränkung der Datennutzung verlangen,</li><li>wir die Daten für den vorgesehenen Zweck nicht mehr benötigen, Sie diese Daten aber noch zur Geltendmachung oder Verteidigung von Rechtsansprüchen brauchen, oder</li><li>Sie Widerspruch gegen die Verarbeitung der Daten eingelegt haben. Recht auf Datenübertragbarkeit:<br/>Sie können von uns verlangen, dass wir Ihnen Ihre Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zur Verfügung stellen und dass Sie diese Daten einem anderen Verantwortlichen ohne Behinderung durch uns übermitteln können, sofern</li><li>wir diese Daten aufgrund einer von Ihnen erteilten und widerrufbaren Zustimmung oder zur Erfüllung eines Vertrages zwischen uns verarbeiten, und</li><li>diese Verarbeitung mithilfe automatisierter Verfahren erfolgt.
Bei technischer Machbarkeit können Sie von uns eine direkte Übermittlung Ihrer Daten an einen anderen Verantwortlichen verlangen.</li></ul>`,
  },
  {
    label: "Widerspruchsrecht",
    subtitle: true,
    content: `Verarbeiten wir Ihre Daten aus berechtigtem Interesse, so können Sie gegen diese Datenverarbeitung jederzeit Widerspruch einlegen; dies würde auch für ein auf diese Bestimmungen gestütztes Profiling gelten. Wir verarbeiten dann Ihre Daten nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen. Der Verarbeitung Ihrer Daten zum Zweck der Direktwerbung können Sie jederzeit ohne Angabe von Gründen widersprechen.`,
  },
  {
    label: "Beschwerderecht:",
    subtitle: true,
    content: `Sind Sie der Meinung, dass wir bei der Verarbeitung Ihrer Daten gegen deutsches oder europäisches Datenschutzrecht verstoßen, so bitten wir Sie, mit uns Kontakt aufzunehmen, um Fragen aufklären zu können. Sie haben selbstverständlich auch das Recht, sich an die für Sie zuständige Aufsichtsbehörde, das jeweilige Landesamt für Datenschutzaufsicht, zu wenden.<br/><br/>Sofern Sie eines der genannten Rechte uns gegenüber geltend machen wollen, so wenden Sie sich bitte an unseren Datenschutzbeauftragten. Im Zweifel können wir zusätzliche Informationen zur Bestätigung Ihrer Identität anfordern.<br/><br/>Sie haben jederzeit ein Recht auf Auskunft, Berichtigung, Löschung oder Einschränkung der Verarbeitung Ihrer gespeicherten Daten, ein Widerspruchsrecht gegen die Verarbeitung sowie ein Recht auf Datenübertragbarkeit und auf Beschwerde gemäß den Voraussetzungen des Datenschutzrechts.`,
  },
  {
    label: "Recht auf Auskunft:",
    subtitle: true,
    content: `Sie können von uns eine Auskunft verlangen, ob und in welchem Ausmaß wir Ihre Daten verarbeiten.`,
  },
  {
    label: "Recht auf Berichtigung:",
    subtitle: true,
    content: `Verarbeiten wir Ihre Daten, die unvollständig oder unrichtig sind, so können Sie jederzeit deren Berichtigung bzw. deren Vervollständigung von uns verlangen.`,
  },
  {
    label: "Recht auf Löschung:",
    subtitle: true,
    content: `Sie können von uns die Löschung Ihrer Daten verlangen, sofern wir diese unrechtmäßig verarbeiten oder die Verarbeitung unverhältnismäßig in Ihre berechtigten Schutzinteressen eingreift. Bitte beachten Sie, dass es Gründe geben kann, die einer sofortigen Löschung entgegenstehen, z.B. im Fall von gesetzlich geregelten Aufbewahrungspflichten.`,
  },
  {
    label: "Änderungen dieser Datenschutzerklärung",
    subtitle: false,
    content: `Wir behalten uns das Recht vor, unsere Datenschutzerklärungen zu ändern falls dies aufgrund neuer Technologien notwendig sein sollte. Bitte stellen Sie sicher, dass Ihnen die aktuellste Version vorliegt. Werden an dieser Datenschutzerklärung grundlegende Änderungen vorgenommen, geben wir diese auf unserer Website bekannt.<br/><br/>Alle Interessenten und Besucher unserer Internetseite erreichen uns in Datenschutzfragen unter:<br/><br/>Herrn Gerald Lill<br/>Projekt 29 GmbH & Co. KG Ostengasse 14<br/>93047 Regensburg<br/><br/>Tel.: 0941 2986930<br/>Fax: 0941 29869316 E-Mail: gl@projekt29.de Internet: www.projekt29.de`,
  },
];

const PRIVACY_CONTENT_2 = [
  {
    content: `Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre personenbezogenen Daten (kurz „Daten“) daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen. Mit dieser Datenschutzerklärung wollen wir Sie über die Verarbeitung Ihrer Daten in unserem Unternehmen und die Ihnen zustehenden datenschutzrechtlichen Ansprüche und Rechte umfassend im Sinne des Art. 13 der Europäischen Datenschutz-Grundverordnung (EU DS-GVO) informieren.`,
  },
  {
    label:
      "Wer ist für die Datenverarbeitung verantwortlich und an wen können Sie sich wenden?",
    subtitle: false,
    content: `Verantwortlich ist<br/>mb support GmbH Friedenstraße 18 D-93053 Regensburg Geschäftsführer Martin Böddecker<br/><br/>Der betriebliche Datenschutzbeauftragte ist<br/>Gerald Lill<br/>Projekt 29 GmbH & Co. KG Ostengasse 14<br/>93047 Regensburg<br/>E-Mail: gl@projekt29.de Tel.: 0941-2986930`,
  },
  {
    label:
      "Welche Daten werden verarbeitet und aus welchen Quellen stammen diese Daten?",
    subtitle: false,
    content: `Wir verarbeiten die Daten, die wir im Rahmen der Vertragsanbahnung bzw. -abwicklung, anhand von Einwilligungen oder im Rahmen Ihrer Bewerbung bei uns bzw. im Rahmen Ihrer Mitarbeiterschaft bei uns von Ihnen erhalten haben.<br/><br/>Zu den personenbezogenen Daten zählen:<br/>hre Stamm-/Kontaktdaten, hierzu zählen bei Kunden z.B. Vor- und Zuname, Adresse,<br/><br/>Kontaktdaten (E-Mail-Adresse, Telefonnummer, Fax), Bankdaten.<br/><br/>Bei Bewerbern und Mitarbeitern zählen hierzu z.B. Vor- und Zuname, Adresse, Kontaktdaten (E-Mail-Adresse, T elefonnummer , Fax), Geburtsdatum, Daten aus Lebenslauf und Arbeitszeugnissen, Bankdaten, Religionszugehörigkeit.<br/><br/>Bei Geschäftspartnern zählen hierzu z.B. die Bezeichnung ihrer rechtsgeschäftlichen Vertreter, Firma, Handelsregistemummer, Umsatzsteuer-ldNr., Betriebsnummer, Adresse, Ansprechpartner-Kontaktdaten (E-Mail-Adresse, Telefonnummer, Fax), Bankdaten.<br/><br/>Darüber hinaus verarbeiten wir auch folgende sonstige personenbezogene Daten:<ul><li>Informationen über Art und Inhalt von Vertragsdaten, Auftragsdaten, Umsatz- und Belegdaten, Kunden- und Lieferantenhistorie sowie Beratungsunterlagen,</li><li>Werbe- und Vertriebsdaten,</li><li>Informationen aus Ihrem elektronischen Verkehr mit uns (z.B. IP-Adresse, Log-in-Daten),</li><li>sonstige Daten, die wir von Ihnen im Rahmen unserer Geschäftsbeziehung (z.B. in Kundengesprächen) erhalten haben,</li><li>Daten, die wir aus Stamm- / Kontaktdaten sowie sonstigen Daten selbst generieren, wie z.B. mittels Kundenbedarfs- und Kundenpotentialanalysen,</li><li>die Dokumentation Ihrer Zustimmungserklärung für den Erhalt von z.B. Newslettern.</li></ul>`,
  },
  {
    label:
      "Für welche Zwecke und auf welcher Rechtsgrundlage werden die Daten verarbeitet?",
    subtitle: false,
    content: `Wir verarbeiten Ihre Daten im Einklang mit den Bestimmungen der Datenschutz- Grundverordnung (DS-GVO) und dem Bundesdatenschutzgesetz 2018 in der jeweils geltenden Fassung:<ul><li>zur Erfüllung von (vor-)vertraglichen Pflichten (Art 6 Abs. 1lit.b DS-GVO): Die Verarbeitung Ihrer Daten erfolgt für die Vertragsabwicklung online oder in einer unserer Filialen, zur Vertragsabwicklung Ihrer Mitarbeiterschaft in unserem Unternehmen. Die Daten werden insbesondere bei Geschäftsanbahnung und bei Durchführung der Verträge mit Ihnen verarbeitet.</li><li>zur Erfüllung rechtlicher Verpflichtungen (Art 6 Abs. 1 lit.c DS-GVO):<br/>Eine Verarbeitung Ihrer Daten ist zum Zweck der Erfüllung unterschiedlicher gesetzlicher Verpflichtungen z.B. aus dem Handelsgesetzbuch oder der Abgabenordnung erforderlich.<li/><li>zur Wahrung berechtigter Interessen (Art 6 Abs. 1 lit.f DS-GVO):<br/>Aufgrund einer Interessenabwägung kann eine Datenverarbeitung über die eigentliche Erfüllung des Vertrags hinaus zur Wahrung berechtigter Interessen von uns oder Dritten erfolgen. Eine Datenverarbeitung zur Wahrung berechtigter Interessen erfolgt beispielsweise in folgenden Fällen:<ol><li>Werbung oder Marketing (siehe Nr. 4),</li><li>Maßnahmen zur Geschäftssteuerung und Weiterentwicklung von Dienstleistungen und Produkten;</li><li>Führen einer konzernweiten Kundendatenbank zur Verbesserung des Kundenservice</li><li>im Rahmen der Rechtsverfolgung.</li></ol></li><li>im Rahmen Ihrer Einwilligung (Art 6 Abs. 1lit.a DSGVO):<br/>Wenn Sie uns eine Einwilligung zur Verarbeitung Ihrer Daten erteilt haben, z.B. zur Zusendung unseres Newsletters.</li></ul>`,
  },
  {
    label: "Verarbeitung personenbezogener Daten zu Werbezwecken",
    subtitle: false,
    content: `Der Verwendung Ihrer personenbezogenen Daten für Werbezwecke können Sie jederzeit insgesamt oder für einzelne Maßnahmen widersprechen, ohne dass hierfür andere als die Übermittlungskosten nach den Basistarifen entstehen.<br/><br/>Wir sind unter den gesetzlichen Voraussetzungen des § 7 Abs.3 UWG berechtigt, die E- Mail-Adresse, die Sie bei Vertragsabschluss angegeben haben, zur Direktwerbung für eigene ähnliche Waren oder Dienstleistungen zu nutzen. Diese Produktempfehlungen erhalten Sie von uns unabhängig davon, ob Sie einen Newsletter abonniert haben.<br/><br/>Wenn Sie keine derartigen Empfehlungen per E-Mail von uns erhalten möchten, können Sie der Verwendung Ihrer Adresse zu diesem Zweck jederzeit widersprechen, ohne dass hierfür andere als die Übermittlungskosten nach den Basistarifen entstehen. Eine Mitteilung in Textform reicht hierfür aus. Selbstverständlich ist in jeder E-Mail immer auch ein Abmeldelink enthalten.`,
  },
  {
    label: "Wer erhält meine Daten?",
    subtitle: false,
    content: `Wenn wir einen Dienstleister im Sinn einer Auftragsverarbeitung einsetzen, bleiben wir dennoch für den Schutz Ihrer Daten verantwortlich. Sämtliche Auftragsverarbeiter sind vertraglich dazu verpflichtet, Ihre Daten vertraulich zu behandeln und nur im Rahmen der Leistungserbringung zu verarbeiten. Die von uns beauftragten Auftragsverarbeiter erhalten Ihre Daten, sofern diese die Daten zur Erfüllung ihrer jeweiligen Leistung benötigen. Diese sind z.B. IT- Dienstleister, die wir für Betrieb und Sicherheit unseres IT-Systems benötigen sowie Werbe- und Adressenverlage für eigene Werbeaktionen.<br/><br/>Ihre Daten werden in unserer Kundendatenbank verarbeitet. Die Kundendatenbank unterstützt die Anhebung der Datenqualität der vorhandenen Kundendaten (Dublettenbereinigung, verzogen/verstorben-Kennzeichen, Adresskorrektur), und ermöglicht die Anreicherung mit Daten aus öffentlichen Quellen.<br/><br/>Diese Daten werden den Konzerngesellschaften sofern zur Vertragsabwicklung notwendig zur Verfügung gestellt. Das Speichern von Kundendaten erfolgt unternehmensbezogen und getrennt, wobei unser Mutterkonzern für die einzelnen teilnehmenden Gesellschaften als Dienstleister fungiert.<br/><br/>Bei Vorliegen einer gesetzlichen Verpflichtung sowie im Rahmen der Rechtsverfolgung können Behörden und Gerichte sowie externe Auditoren Empfänger Ihrer Daten sein. Darüber hinaus können zum Zweck der Vertragsanbahnung und -erfüllung Versicherungen, Banken, Auskunfteien und Dienstleister Empfänger Ihrer Daten sein.`,
  },
  {
    label: "Wie lange werden meine Daten gespeichert?",
    subtitle: false,
    content: `Wir verarbeiten Ihre Daten bis zur Beendigung der Geschäftsbeziehung oder bis zum Ablauf der geltenden gesetzlichen Aufbewahrungsfristen (etwa aus dem Handelsgesetzbuch, der Abgabenordnung, Heimgesetz oder Arbeitszeitgesetz); darüber hinaus bis zur Beendigung von allfälligen Rechtsstreitigkeiten, bei denen die Daten als Beweis benötigt werden.`,
  },
  {
    label: "Werden personenbezogene Daten an ein Drittland übermittelt?",
    subtitle: false,
    content: `Grundsätzlich werden von uns keine Daten an ein Drittland übermittelt. Eine Übermittlung findet im Einzelfall nur auf Grundlage eines Angemessenheitsbeschlusses der Europäischen Kommission, Standardvertragsklauseln, geeigneter Garantien oder Ihrer ausdrücklichen Zustimmung statt.`,
  },
  {
    label: "Welche Datenschutzrechte habe ich?",
    subtitle: false,
    content: `Sie haben jederzeit ein Recht auf Auskunft, Berichtigung, Löschung oder Einschränkung der Verarbeitung Ihrer gespeicherten Daten, ein Widerspruchsrecht gegen die Verarbeitung sowie ein Recht auf Datenübertragbarkeit und auf Beschwerde gemäß den Voraussetzungen des Datenschutzrechts.`,
  },
  {
    label: "Recht auf Auskunft:",
    subtitle: true,
    content: `Sie können von uns eine Auskunft verlangen, ob und in welchem Ausmaß wir Ihre Daten verarbeiten.`,
  },
  {
    label: "Recht auf Berichtigung:",
    subtitle: true,
    content: `Verarbeiten wir Ihre Daten, die unvollständig oder unrichtig sind, so können Sie jederzeit deren Berichtigung bzw. deren Vervollständigung von uns verlangen.`,
  },
  {
    label: "Recht auf Löschung:",
    subtitle: true,
    content: `Sie können von uns die Löschung Ihrer Daten verlangen, sofern wir diese unrechtmäßig verarbeiten oder die Verarbeitung unverhältnismäßig in Ihre berechtigten Schutzinteressen eingreift. Bitte beachten Sie, dass es Gründe geben kann, die einer sofortigen Löschung entgegenstehen, z.B. im Fall von gesetzlich geregelten Aufbewahrungspflichten. Unabhängig von der Wahrnehmung Ihres Rechts auf Löschung, werden wir Ihre Daten umgehend und vollständig löschen, soweit keine diesbezügliche rechtsgeschäftliche oder gesetzliche Aufbewahrungspflicht entgegensteht.`,
  },
  {
    label: "Recht auf Einschränkung der Verarbeitung:",
    subtitle: true,
    content: `Sie können von uns die Einschränkung der Verarbeitung Ihrer Daten verlangen, wenn<ul><li>Sie die Richtigkeit der Daten bestreiten, und zwar für eine Dauer, die es uns ermöglicht, die Richtigkeit der Daten zu überprüfen.</li><li>die Verarbeitung der Daten unrechtmäßig ist, Sie aber eine Löschung ablehnen und stattdessen eine Einschränkung der Datennutzung verlangen,-  wir die Daten für den vorgesehenen Zweck nicht mehr benötigen, Sie diese Daten aber noch zur Geltendmachung oder Verteidigung von Rechtsansprüchen brauchen, oder</li><li>Sie Widerspruch gegen die Verarbeitung der Daten eingelegt haben</li></ul>`,
  },
  {
    label: "Recht auf Datenübertragbarkeit:",
    subtitle: true,
    content: `Sie können von uns verlangen, dass wir Ihnen Ihre Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zur Verfügung stellen und dass Sie diese Daten einem anderen Verantwortlichen ohne Behinderung durch uns übermitteln können, sofern<ul><li>wir diese Daten aufgrund einer von Ihnen erteilten und widerrufbaren Zustimmung oder zur Erfüllung eines Vertrages zwischen uns verarbeiten, und</li><li>diese Verarbeitung mithilfe automatisierter Verfahren erfolgt.</li></ul>Bei technischer Machbarkeit können Sie von uns eine direkte Übermittlung Ihrer Daten an einen anderen Verantwortlichen verlangen.`,
  },
  {
    label: "Widerspruchsrecht:",
    subtitle: true,
    content: `Verarbeiten wir Ihre Daten aus berechtigtem Interesse, so können Sie gegen diese Datenverarbeitung jederzeit Widerspruch einlegen; dies würde auch für ein auf diese Bestimmungen gestütztes Profiling gelten. Wir verarbeiten dann Ihre Daten nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen. Der Verarbeitung Ihrer Daten zum Zweck der Direktwerbung können Sie jederzeit ohne Angabe von Gründen widersprechen.`,
  },
  {
    label: "Beschwerderecht:",
    subtitle: true,
    content: `Sind Sie der Meinung, dass wir bei der Verarbeitung Ihrer Daten gegen deutsches oder europäisches Datenschutzrecht verstoßen, so bitten wir Sie, mit uns Kontakt aufzunehmen, um Fragen aufklären zu können. Sie haben selbstverständlich auch das Recht, sich an die für Sie zuständige Aufsichtsbehörde, das jeweilige Landesamt für Datenschutzaufsicht, zu wenden.<br/>Sofern Sie eines der genannten Rechte uns gegenüber geltend machen wollen, so wenden Sie sich bitte an unseren Datenschutzbeauftragten. Im Zweifel können wir zusätzliche Informationen zur Bestätigung Ihrer Identität anfordern.`,
  },
  {
    label: "Bin ich zur Bereitstellung von Daten verpflichtet?",
    subtitle: false,
    content: `Die Verarbeitung Ihrer Daten ist zum Abschluss bzw. zur Erfüllung Ihres mit uns eingegangenen Vertrages erforderlich. Wenn Sie uns diese Daten nicht zur Verfügung stellen, werden wir den Abschluss des Vertrags in der Regel ablehnen müssen oder einen bestehenden Vertrag nicht mehr durchführen können und folglich beenden müssen. Sie sind jedoch nicht verpflichtet, hinsichtlich für die Vertragserfüllung nicht relevanter bzw. gesetzlich nicht erforderlicher Daten eine Einwilligung zur Datenverarbeitung zu erteilen.`,
  },
];

export default function PrivacyPage() {
  let countHeading1 = 0;
  let countHeading2 = 0;
  return (
    <>
      <MainContent pageTitle="Datenschutz">
        {PRIVACY_CONTENT_1.map((el) => {
          if (!el.subtitle) countHeading1++;
          return (
            <>
              {el.label && (
                <>
                  {el.subtitle ? (
                    <Typography>
                      <b>{el.label}</b>
                    </Typography>
                  ) : (
                    <Typography variant="h4">
                      {countHeading1}
                      {". "}
                      {el.label}
                    </Typography>
                  )}
                </>
              )}
              <Typography
                dangerouslySetInnerHTML={{ __html: el.content }}
                gutterBottom={true}
              />
            </>
          );
        })}
      </MainContent>
      <br />
      <br />
      <MainContent pageTitle="Informationspflicht gem. Art. 13 DSGVO">
        {PRIVACY_CONTENT_2.map((el) => {
          if (!el.subtitle && el.label) countHeading2++;
          return (
            <>
              {el.label && (
                <>
                  {el.subtitle ? (
                    <Typography>
                      <b>{el.label}</b>
                    </Typography>
                  ) : (
                    <Typography variant="h4">
                      {countHeading2}
                      {". "}
                      {el.label}
                    </Typography>
                  )}
                </>
              )}
              <Typography
                dangerouslySetInnerHTML={{ __html: el.content }}
                gutterBottom={true}
              />
            </>
          );
        })}
      </MainContent>
    </>
  );
}
