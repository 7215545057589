export default class FormView {
  constructor(
    status,
    userInputs,
    resetReason,
    invalidFields,
    name,
    html,
    css,
    signatureRequired,
    urlImprint,
    urlPrivacy,
    urlTOS,
    initialValues,
    createdAt,
    updatedAt
  ) {
    this.status = status;
    this.userInputs = userInputs;
    this.resetReason = resetReason;
    this.invalidFields = invalidFields;
    this.name = name;
    this.html = html;
    this.css = css;
    this.signatureRequired = signatureRequired;
    this.urlImprint = urlImprint;
    this.urlPrivacy = urlPrivacy;
    this.urlTOS = urlTOS;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.initialValues = initialValues;
  }
}
