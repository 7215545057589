import PropTypes from "prop-types";
import { Container } from "@mui/material";
import { MemoizedNavbar, MainContent } from "components";
import "./BaseLayout.css";

/**
 * @param {string} pageTitle
 */
export default function BaseLayout(props) {
  return (
    <Container maxWidth={false} className="BaseLayout-root">
      <MemoizedNavbar />
      <MainContent pageTitle={props.pageTitle} afterTitle={props.afterTitle}>
        {props.children}
      </MainContent>
    </Container>
  );
}

BaseLayout.propTypes = {
  pageTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.PropTypes.shape({
      text: PropTypes.string,
      variant: PropTypes.string,
    }),
  ]),
  children: PropTypes.node,
  publicPage: PropTypes.bool,
  afterTitle: PropTypes.node,
};

BaseLayout.defaultProps = {
  publicPage: false,
};
