import RequestResponse from "./RequestResponse";

export default class Service {
  constructor(basePath) {
    this.apiUrl = process.env.REACT_APP_API_URL;
    //this.model = model;
    this.basePath = basePath;
  }

  /**
   * @param {string} endpoint
   */
  async makeRequest(endpoint, method, body) {
    let jsonBody = null;
    if (body) jsonBody = JSON.stringify(body);
    let myResponse = new RequestResponse();
    let headers = {
      "Content-Type": "application/json",
    };

    /*
    if (Cookies.get("token")) {
      headers["Authorization"] = "Bearer " + Cookies.get("access_token");
    }*/
    return fetch(this.apiUrl + endpoint, {
      method: method,
      headers: headers,
      body: jsonBody,
    })
      .then((response) => {
        const contentType = response.headers.get("content-type");
        const isJson = contentType && contentType.includes("application/json");
        myResponse.setSuccess(String(response.status).charAt(0) == 2);
        myResponse.setMessage(response.statusText);
        if (!myResponse.isSuccess() && !isJson) {
          throw new Error(
            "Oops, we haven't got JSON! (" + response.status + ")"
          );
        }
        return isJson ? response.json() : null;
      })
      .then((data) => {
        if (data) {
          if (data.error && data.error.message) {
            throw new Error(data.error.message);
          }
          myResponse.setData(data);
        }
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
        myResponse.setMessage(error.message);
        myResponse.setSuccess(false);
      })
      .then(() => {
        return myResponse;
      });
  }

  /**
   * @param {string} endpoint
   */
  async makeBlobRequest(endpoint, method, fileFormat, body) {
    let jsonBody = null;
    if (body) jsonBody = JSON.stringify(body);
    let myResponse = new RequestResponse();
    let headers = {
      "Content-Type": "application/json",
    };
    return fetch(this.apiUrl + endpoint, {
      method: method,
      headers: headers,
      body: jsonBody,
    })
      .then((response) => {
        const contentType = response.headers.get("content-type");
        const isBlob = contentType && contentType.includes(fileFormat);

        myResponse.setSuccess(String(response.status).charAt(0) == 2);
        myResponse.setMessage(response.statusText);
        if (!myResponse.isSuccess() || !isBlob) {
          throw new Error(
            "Oops, we haven't got the File (" +
              fileFormat +
              ")! (" +
              response.status +
              ", " +
              contentType +
              ")"
          );
        }
        return isBlob ? response.blob() : null;
      })
      .then((data) => {
        if (data) {
          if (data.error && data.error.message) {
            throw new Error(data.error.message);
          }
          myResponse.setData(data);
        }
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
        myResponse.setMessage(error.message);
        myResponse.setSuccess(false);
      })
      .then(() => {
        return myResponse;
      });
  }

  download(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    // the filename you want
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  preview(blob) {
    const url = window.URL.createObjectURL(blob);
    window.open(url);
    window.URL.revokeObjectURL(url);
  }
}
