export default class RequestResponse {
  constructor(success = false, message, data) {
    this.success = success;
    this.message = message;
    this.data = data;
  }

  /**
   * @param {boolean} success
   */
  setSuccess(success) {
    this.success = success;
  }

  /**
   * @return {boolean} success
   */
  isSuccess() {
    return this.success;
  }

  /**
   * @param {string} message
   */
  setMessage(message) {
    this.message = message;
  }

  /**
   * @return {string} message
   */
  getMessage() {
    return this.message;
  }

  /**
   * @param {Object[]|Object} data
   */
  setData(data) {
    this.data = data;
  }

  /**
   * @return {Object[]|Object} data
   */
  getData() {
    return this.data;
  }
}
