import React from "react";
import Toolbar from "@mui/material/Toolbar";
import PropTypes from "prop-types";
import { AppBar, Typography, Link } from "@mui/material";
import "./Navbar.css";
import { OTHER_PATHS } from "views/routes";
import { Link as RouterLink } from "react-router-dom";
export default function Navbar() {
  return (
    <AppBar
      position="fixed"
      className="Navbar-root"
      color="appBar"
      sx={{ borderColor: "primary.main" }}
    >
      <Toolbar className="Navbar-toolbar">
        <Typography variant="h6" id="Navbar-logo">
          <a
            href={process.env.REACT_APP_LANDING_PAGE_URL}
            target="_blank"
            rel="noreferrer"
          >
            <img src="./logo.svg" id="logo" />
          </a>
        </Typography>
        {location.pathname === "/form" ? (
          <>
            <Link
              className="Navbar-router-link"
              target="_blank"
              underline="hover"
              id="Navbar-imprint-client"
            >
              Impressum
            </Link>
            <Link
              className="Navbar-router-link"
              target="_blank"
              underline="hover"
              id="Navbar-privacy-client"
            >
              Datenschutz
            </Link>
          </>
        ) : (
          <>
            <RouterLink
              className="Navbar-router-link"
              target="_blank"
              to={OTHER_PATHS.IMPRINT}
            >
              Impressum
            </RouterLink>
            <RouterLink
              className="Navbar-router-link"
              target="_blank"
              to={OTHER_PATHS.PRIVACY}
            >
              Datenschutz
            </RouterLink>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export const MemoizedNavbar = React.memo(Navbar);

Navbar.propTypes = {
  onClickChangeClient: PropTypes.func,
};
