import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

/**
 * @description Simple dialog container
 * @param {string} title
 * @param {func}   handleCancel
 * @param {func}   handleOk
 * @param {bool}   open
 * @param {string|number|bool}  maxWidth
 * @param {string|number|bool}  fullWidth
 * @param {string}  okBtnLabel
 * @param {bool}   fullScreen
 */
export default function DialogContainer(props) {
  const { open } = props;
  return (
    <Dialog
      open={open}
      maxWidth={props.maxWidth}
      fullWidth={props.fullWidth}
      className="DialogContainer-root"
      fullScreen={props.fullScreen}
    >
      {props.title && (
        <DialogTitle>
          {props.title}
          {props.handleCancel && props.fullScreen && (
            <IconButton
              className="DialogContainer-cancel-top"
              aria-label="close"
              onClick={props.handleCancel}
              sx={{
                position: "absolute",
                right: 12,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          )}
        </DialogTitle>
      )}
      <DialogContent dividers>{props.children}</DialogContent>
      {(props.handleCancel || props.handleOk) && (
        <DialogActions>
          {props.handleCancel && !props.fullScreen && (
            <Button onClick={props.handleCancel}>{props.cancelBtnLabel}</Button>
          )}
          {props.handleOk && (
            <Button autoFocus onClick={props.handleOk}>
              {props.okBtnLabel}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}

DialogContainer.propTypes = {
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  maxWidth: PropTypes.any,
  fullWidth: PropTypes.any,
  children: PropTypes.node,
  okBtnLabel: PropTypes.string,
  cancelBtnLabel: PropTypes.string,
  fullScreen: PropTypes.bool,
};

DialogContainer.defaultProps = {
  fullScreen: false,
  fullWidth: true,
  maxWidth: "xl",
  okBtnLabel: "Ok",
  cancelBtnLabel: "Abbrechen",
};
