import { ArrowBackIos } from "@mui/icons-material";
import { Box, Breadcrumbs, IconButton, Link, Typography } from "@mui/material";
import PropTypes from "prop-types";
import "./MainContent.css";

export default function MainContent(props) {
  return (
    <Box {...props.boxProps} className="MainContent-root">
      {props.breadcrumbs && (
        <Breadcrumbs aria-label="breadcrumb">
          {props.breadcrumbs.map((el, index) => (
            <span key={index}>
              {el.href ? (
                <Link href={el.href} underline="hover" color="inherit">
                  {el.text}
                </Link>
              ) : (
                <Typography color="text.primary">
                  {el.text ? el.text : el}
                </Typography>
              )}
            </span>
          ))}
        </Breadcrumbs>
      )}
      {(props.pageTitle || props.afterTitle || props.goBack) && (
        <div className="MainContent-heading">
          {props.goBack && (
            <IconButton onClick={props.goBack}>
              <ArrowBackIos />
            </IconButton>
          )}
          {props.pageTitle && (
            <Typography
              variant={props.pageTitleVariant}
              className="MainContent-title"
            >
              {props.pageTitle}
            </Typography>
          )}
          {props.afterTitle}
        </div>
      )}
      <div className="MainContent-content">{props.children}</div>
    </Box>
  );
}

MainContent.propTypes = {
  pageTitle: PropTypes.string,
  pageTitleVariant: PropTypes.string,
  afterTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.number,
  ]),
  children: PropTypes.node,
  boxProps: PropTypes.object,
  goBack: PropTypes.func,
  breadcrumbs: PropTypes.array,
};

MainContent.defaultProps = {
  pageTitleVariant: "h1",
};
