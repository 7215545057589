import PropTypes from "prop-types";
import {
  Button,
  FormGroup,
  Paper,
  FormControlLabel,
  Checkbox,
  Typography,
  Link as Mui_Link,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Save, Send, PictureAsPdf } from "@mui/icons-material";
import { SHADOW_ROOT_CONTAINER_ID, FORM_CONTAINER_ID } from "../consts";
import { FormContainerUtils } from "./FormContainerUtils";
import AssetsService from "services/AssetsService";

export default function FormOpen(props) {
  const [agreeLegal, setAgreeLegal] = useState(false);
  const [formDataUtils, setFormDataUtils] = useState();
  let initFormDone = false;

  useEffect(() => {
    if (!props.formData || initFormDone || formDataUtils) return;
    if (!props.formData.html && !props.formData.css) return;
    const shadowRootContainer = document.querySelector(
      "#" + SHADOW_ROOT_CONTAINER_ID
    );
    if (!shadowRootContainer.shadowRoot) {
      let htmlEl = document.createElement("html"),
        headEl = document.createElement("head"),
        bodyEl = document.createElement("body"),
        formEl = document.createElement("form");
      var meta = document.createElement("meta");
      meta.name = "viewport";
      meta.content = "width=300, initial-scale=1";
      headEl.appendChild(meta);
      headEl.insertAdjacentHTML(
        "beforeend",
        "<style>" +
          `.incorrect_field_validation {
                          border: #a31f1f 1px solid !important;
                          background-color: #ebd3d3 !important;
                        }
                        ` +
          "</style>"
      );

      headEl.insertAdjacentHTML(
        "beforeend",
        `<link rel="stylesheet" href="${new AssetsService().getFontsUrl()}" />`
      );

      if (props.formData.css) {
        headEl.insertAdjacentHTML(
          "beforeend",
          "<style>body,html{margin:0;}" + props.formData.css + "</style>"
        );
      }
      formEl.innerHTML = props.formData.html;
      formEl.id = FORM_CONTAINER_ID;
      bodyEl.appendChild(formEl);
      htmlEl.appendChild(headEl);
      htmlEl.appendChild(bodyEl);
      let shadow = shadowRootContainer.attachShadow({ mode: "open" });
      htmlEl.setAttribute("height", "100%");
      htmlEl.setAttribute("width", "100%");
      shadow.appendChild(htmlEl);
      // INIT
      const formDataUtils = new FormContainerUtils(
        document.querySelector("#" + SHADOW_ROOT_CONTAINER_ID).shadowRoot,
        props.formData.userInputs,
        props.formData.invalidFields,
        props.formData.createdAt,
        props.formData.initialInputs,
        props.formData.status
      );
      setFormDataUtils(formDataUtils);
    }
  }, [props.formData]);

  return (
    <div className="FormContainer-root">
      {props.formData.resetReason && (
        <div className="FormContainer-restReason incorrect_field_validation">
          <Typography variant="h3" gutterBottom>
            Information
          </Typography>
          <Typography>{props.formData.resetReason}</Typography>
        </div>
      )}
      {props.formData.html && (
        <div>
          <Paper elevation={6}>
            <div id={SHADOW_ROOT_CONTAINER_ID} />
          </Paper>

          {props.needsLegalAgreement && (
            <FormGroup className="FormContainer-agreeLegal">
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={(e) => setAgreeLegal(Boolean(e?.target?.checked))}
                    checked={Boolean(agreeLegal)}
                  />
                }
                label={
                  <span>
                    <span>Ich stimme</span>
                    {props.formData.urlPrivacy && (
                      <>
                        <span> der </span>
                        <Mui_Link
                          href={props.formData.urlPrivacy}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Datenschutzvereinbarung
                        </Mui_Link>
                      </>
                    )}
                    {props.formData.urlPrivacy && props.formData.urlTOS && (
                      <span>, sowie</span>
                    )}
                    {props.formData.urlTOS && (
                      <>
                        {" "}
                        <span> den </span>{" "}
                        <Mui_Link
                          href={props.formData.urlTOS}
                          rel="noreferrer"
                          target="_blank"
                        >
                          Allgemeinen Geschäftsbedingungen
                        </Mui_Link>
                      </>
                    )}

                    <span> zu.</span>
                  </span>
                }
              />
            </FormGroup>
          )}
          {process.env.NODE_ENV === "development" && (
            <Button
              className="FormContainer-sendButton"
              variant="contained"
              onClick={() =>
                formDataUtils.getFormData(
                  false,
                  props.onValidationError,
                  props.onPdfPreview
                )
              }
              size="large"
              startIcon={<PictureAsPdf />}
            >
              PDF Vorschau
            </Button>
          )}
          <Button
            className="FormContainer-sendButton"
            variant="contained"
            onClick={() =>
              formDataUtils.getFormData(
                true,
                props.onValidationError,
                props.onSave
              )
            }
            size="large"
            startIcon={<Save />}
            disabled={props.needsLegalAgreement && !agreeLegal}
          >
            Entwurf speichern
          </Button>
          <Button
            className="FormContainer-sendButton"
            variant="contained"
            onClick={() =>
              formDataUtils.getFormData(
                false,
                props.onValidationError,
                props.onSave
              )
            }
            size="large"
            startIcon={<Send />}
            disabled={props.needsLegalAgreement && !agreeLegal}
          >
            Absenden
          </Button>
        </div>
      )}
    </div>
  );
}

FormOpen.propTypes = {
  onPdfPreview: PropTypes.func,
  onValidationError: PropTypes.func,
  onSave: PropTypes.func,
  onError: PropTypes.func,
  formData: PropTypes.object,
  needsLegalAgreement: PropTypes.bool,
};
