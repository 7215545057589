import BaseLayout from "layouts/BaseLayout/BaseLayout";
import { Route, Routes } from "react-router-dom";
import FormPage from "views/FormPage/FormPage";
import EmbeddedFormPage from "views/EmbeddedFormPage/EmbeddedFormPage";
import ImprintPage from "views/ImprintPage/ImprintPage";
import LandingPage from "views/LandingPage/LandingPage";
import PrivacyPage from "views/PrivacyPage/PrivacyPage";
import NotFoundPage from "views/NotFoundPage/NotFoundPage";

function App() {
  return (
    <div className="App-mb-forms-portal">
      <BaseLayout>
        <Routes>
          <Route path="" index element={<LandingPage />} />
          <Route index path="*" element={<NotFoundPage />} />

          <Route path="form" element={<FormPage />} />
          <Route path="form/embed" element={<EmbeddedFormPage />} />
          <Route path="imprint" element={<ImprintPage />} />
          <Route path="privacy" element={<PrivacyPage />} />
        </Routes>
      </BaseLayout>
    </div>
  );
}

export default App;
