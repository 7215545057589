import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
} from "@mui/material";
import DialogContainer from "components/DialogContainer/DialogContainer";
import PropTypes from "prop-types";
import fill_form_img from "./images/fill_form.jpg";
import floppy_disc from "./images/floppy_disc.jpg";
import transfer_data from "./images/transfer_data.jpg";
import "./WelcomeDialog.css";

function FeatureCard(props) {
  return (
    <Grid item sm={12} md={4} lg={4}>
      <Card sx={{ maxWidth: 345 }} className="FeatureCard-root">
        <CardMedia
          component="img"
          height="140"
          image={props.img}
          alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {props.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {props.text}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}

/**
 * @param {bool} open
 * @param {function} onClose
 */
export default function WelcomeDialog(props) {
  return (
    <>
      <DialogContainer open={props.open}>
        <Typography
          variant="h3"
          gutterBottom
          className="WelcomeDialog-title"
          align="center"
        >
          Sichere und digitale Datenübertragung mit mb Forms
        </Typography>
        <Grid container className="WelcomeDialog-features" spacing={2}>
          <FeatureCard
            title="Ausfüllen"
            text="Füllen Sie Ihr Formular bequem an Ihrem Endgerät aus."
            img={fill_form_img}
          />

          <FeatureCard
            title="Herunterladen"
            text="Speichern Sie Entwürfe ab und laden Sie Ihr ausgefülltes Formular herunter."
            img={floppy_disc}
          />

          <FeatureCard
            title="Übermitteln"
            text="Ihre Eingaben werden sicher und direkt versendet."
            img={transfer_data}
          />
        </Grid>
        <Button
          className="WelcomeDialog-start-btn"
          variant="contained"
          size="large"
          onClick={props.handleOk}
        >
          Los gehts!
        </Button>
      </DialogContainer>
    </>
  );
}

WelcomeDialog.propTypes = {
  open: PropTypes.bool,
  handleOk: PropTypes.func,
};

FeatureCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  img: PropTypes.string,
};
