import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./FormContainer.css";
import { MainContent } from "components";
import FormDeleted from "./FormStates.jsw/FormDeleted";
import FormCanceled from "./FormStates.jsw/FormCanceled";
import FormOpen from "./FormStates.jsw/FormOpen";
import FormClosed from "./FormStates.jsw/FormClosed";
import useSecureHtml from "hooks/useSecureHtml";

export default function FormContainer(props) {
  const [formData, setFormData] = useState();
  const SHOW_FROMS_STATUS = ["open", "read", "draft"];
  const { getSecureHtml } = useSecureHtml();

  useEffect(() => {
    const sanitizeForm = async (newForm) => {
      let formView = newForm;
      if (formView) {
        if (formView.html) formView.html = await getSecureHtml(formView.html);
        await setFormData(formView);
      }
    };
    if (props.portalForm) sanitizeForm(props.portalForm);
  }, [props.portalForm]);

  return (
    <>
      <MainContent pageTitle="">
        {formData?.status && (
          <>
            {SHOW_FROMS_STATUS.includes(formData.status) && (
              <>
                <FormOpen
                  formData={formData}
                  onPdfPreview={props.onPdfPreview}
                  onSave={props.onSave}
                  onError={props.onError}
                  onValidationError={props.onValidationError}
                  needsLegalAgreement={props.needsLegalAgreement}
                />
              </>
            )}
            {formData.status === "canceled" && <FormCanceled />}
            {formData.status === "closed" && <FormClosed />}
            {formData.status === "deleted" && <FormDeleted />}
          </>
        )}
      </MainContent>
    </>
  );
}

FormContainer.propTypes = {
  onPdfPreview: PropTypes.func,
  onValidationError: PropTypes.func,
  onSave: PropTypes.func,
  onError: PropTypes.func,
  portalForm: PropTypes.object,
  needsLegalAgreement: PropTypes.bool,
};
