import { Typography } from "@mui/material";
import { MainContent } from "components";

export default function ImprintPage() {
  return (
    <MainContent pageTitle="Impressum">
      <Typography>
        mb Support GmbH <br />
        Friedenstraße 18 <br />
        93053 Regensburg <br />
        <br />
        Telefon: +49 941 94 260-0 <br />
        Fax: +49 941 94 260-111 <br />
        E-Mail: info@mbsupport.de <br />
        <br />
        Website: www.mbsupport.de <br />
        <br />
        Geschäftsführer Martin Böddecker <br />
        Registergericht Regensburg HRB6469 <br />
        UST-ID Nummer DE813878197 <br />
      </Typography>
    </MainContent>
  );
}
